<template>
  <!-- 弹窗组件 -->
  <div>
    <div v-if="status && Person">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 1000"
        @click.stop="hide"
      ></div>
      <div class="el-mask-company animated faster fadeIn">
        <div class="row-end">
          <img
            src="../../../assets/glxt/32.png"
            class="el-img-cha pointer"
            @click="hide"
            alt=""
          />
        </div>
        <div class="el-cv-user">
          <img
            :src="Person.avatar_url"
            class="el-cv-avatar"
            alt=""
            v-if="Person.avatar_url"
          />
          <img class="el-cv-avatar" src="../../../assets/login/922028.png" v-else />
          <div class="el-cv-user-text">
            <div class="el-user-name">{{ Person.name }}</div>
            <div class="row el-cv-center-text">
              {{ Person.gender.text }}｜{{ Person.other_info.age }}岁｜工作{{
                Person.other_info.work_year
              }}年｜<span v-if="Person.school.length > 0">{{
                Person.school[0].education
              }}</span>
              <span v-else>无</span> ｜{{ Person.other_info.job_status }}
            </div>
            <div class="el-user-center-title">个人优势</div>
            <div class="el-user-center-dvantage">{{ Person.detail.advantage }}</div>
            <div class="el-user-center-title">求职期望</div>
            <div class="row el-cv-center-text" v-for="item in Person.job_intention" :key="item.id">
            {{ item.one_position }} ｜ {{ item.salary_min }}-{{
              item.salary_max
            }}
            ｜ {{ item.position_name }} ｜ {{ item.province }}
          </div>
            <div class="row el-cv-center-text" v-if="Person.job_intention.length==0">无</div>
            <div class="el-user-center-title">工作经历</div>
            <div class="el-user-eork-list" v-for="item in Person.work" :key="item.id">
              <div class="row el-user-center-work">
                <div>{{ item.job_times }}</div>
                <div>{{ item.company_name }}</div>
                <div>{{ item.work_name }}</div>
              </div>
              <div class="row el-user-work-text">
                <div>内容：</div>
                <div>
                  <div
                    class="el-user-work-item"
                    v-for="(itemOne, index) in item.splitAdd"
                    :key="item.id + index"
                  >
                    {{ itemOne }}
                  </div>
                </div>
              </div>
            </div>
            <div class="el-user-center-title">教育经历</div>
            <div class="el-user-eork-list" v-for="item in Person.school" :key="item.id">
              <div class="row el-user-center-work">
                <div>{{ item.school_times }}</div>
                <div>{{ item.education }}</div>
                <div>{{ item.school_name }}</div>
                <div>{{ item.major }}</div>
              </div>
              <div class="row el-user-work-text">
                <div>在校经历：</div>
                <div>
                  <div
                    class="el-user-work-item"
                    v-for="(itemOne, index) in experience_one"
                    :key="index + item.id"
                  >
                    {{ itemOne }}
                  </div>
                </div>
              </div>
            </div>
            <div class="el-user-center-title">资格证书</div>
            <div class="el-user-span-div" v-if="Person.certificate">
              <div
                class="el-user-span"
                v-for="(item, index) in Person.certificate.certificates"
                :key="index"
                >{{ item }}</div
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../api/user";
export default {
  props: {},
  data() {
    return {
      status: false,
      Person: null, //简历相关的数据
      experience_one: [],
    };
  },
  methods: {
    // 获取简历信息
    getResume() {
      api.getResume().then((res) => {
        if (res.code == 200) {
          if (res.data.work.length > 0) {
            res.data.work.forEach((item) => {
              if (item.to_hide == 1) {
                item.to_hide = true;
              } else {
                item.to_hide = false;
              }
              if (item.work_content) {
                item.splitAdd = item.work_content.split("\n");
              }
            });
          }
          if (res.data.school.length > 0) {
            if (res.data.school[0].experience) {
              this.experience_one = res.data.school[0].experience.split("\n");
            }
          }
          this.Person = res.data;
        }
      });
    },
    // 显示
    show() {
      this.getResume();
      this.status = true;
    },
    // 隐藏
    hide() {
      this.status = false;
    },
  },
};
</script>
<style lang="less" scoped>

.el-mask-company {
  width: 758px;
  height: 760px;
  background: #ffffff;
  border-radius: 3px;
  padding: 20px 23px 30px 48px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  overflow-y: auto;
  .el-img-cha {
    width: 17px;
    height: 17px;
  }
  .el-cv-user {
    width: 100%;
    display: flex;
    align-items: flex-start;
    .el-cv-avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 23px;
    }
    .el-cv-user-text {
      flex: 1;
      width: 100%;
      color: #414a60;
      font-weight: 400;
      .el-user-name {
        font-size: 21px;
        margin-bottom: 14px;
      }
      .el-cv-center-text {
        font-size: 14px;
        margin-bottom: 38px;
      }
      .el-user-center-title {
        font-size: 18px;
        margin-bottom: 30px;
      }
      .el-user-center-dvantage {
        font-size: 14px;
        margin-bottom: 30px;
      }
      .el-user-eork-list {
        margin-bottom: 34px;
        .el-user-center-work {
          font-size: 14px;
          margin-bottom: 30px;
          width: 430px;
          justify-content: space-between;
        }
        .el-user-work-text {
          font-size: 14px;
          line-height: 23px;
        }
      }
      .el-user-span {
        font-size: 14px;
        margin-top: 10px;
      }
      .el-user-span-div span:first-child {
        margin-top: 0;
      }
    }
  }
}
.el-mask-company ::-webkit-scrollbar {
  display: none !important;
}
</style>
