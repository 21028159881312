<template>
	<div>
		<div class="title">
			<div class="title_jl">在线简历</div>
			<div class="title_yl pointer" @click="previewClick">预览</div>
		</div>
		<div class="active">
			<div class="activeText" v-if="Person">
				<div v-if="Person.detail" class="activeTextr">
					<div class="activeName">
						<div class="nameleft">{{ Person.name }}</div>
						<img class="nameimg" :src="Person.avatar_url" v-if="Person.avatar_url" />
						<img class="nameimg" src="../../../assets/login/922028.png" v-else />
					</div>
					<div class="activeExperience">
						<div class="active_jingyan">
							<i class="el-icon-suitcase experience_icon"></i>
							<div class="experience">{{ Person.other_info.work_year }}年经验</div>
						</div>
						<div class="active_xt"></div>
						<div class="active_jingyan">
							<i class="el-icon-suitcase experience_icon"></i>
							<div class="experience">大专</div>
						</div>
						<div class="active_xt" v-if="Person.other_info.job_status"></div>
						<div class="active_jingyan" v-if="Person.other_info.job_status">
							<i class="el-icon-suitcase experience_icon"></i>
							<div class="experience">{{ Person.other_info.job_status }}</div>
						</div>
					</div>
					<div class="activeExperience" style="margin-top: 10px">
						<div class="active_jingyan">
							<i class="el-icon-suitcase experience_icon"></i>
							<div class="experience">{{ Person.other_info.age }}岁</div>
						</div>
						<div class="active_xt"></div>
						<div class="active_jingyan">
							<i class="el-icon-suitcase experience_icon"></i>
							<div class="experience">{{ Person.phone }}</div>
						</div>
					</div>
				</div>
				<div v-else class="addition activeTextr">
					<div class="addition_top">
						<div class="top_left">基本信息</div>
						<div class="top_right pointer" @click="navTo('/my-resume/basic')">
							<div class="">
								<img src="../../../assets/glxt/10.png" alt="" />
							</div>
							<div class="right_tianjia">添加</div>
						</div>
					</div>
					<div class="addition_up">展示您的基本信息，获得更多关注</div>
				</div>
				<div v-if="educationalShow" class="addition activeTextr">
					<div class="addition_top">
						<div class="top_left">个人优势</div>
						<div class="top_right pointer" @click="educationalShow = false">
							<div class="">
								<img src="../../../assets/glxt/10.png" alt="" v-if="!query.advantage" />
								<img src="../../../assets/glxt/9.png" v-else alt="" />
							</div>
							<div class="right_tianjia">{{ query.advantage ? "修改" : "添加" }}</div>
						</div>
					</div>
					<div class="addition_up">
						{{ query.advantage ? query.advantage : "展示您的个人优势，获得更多关注" }}
					</div>
				</div>
				<!-- 个人优势编辑 -->
				<div v-else class="redact" style="margin-top: 21px">
					<div class="active_bianji">
						<div class="acvive_grys">
							<div class="grys_title">个人优势</div>
							<div class="grys_flex wfxz">
								<img class="grys_img" src="../../../assets/login/jianli_icon1.png" />
								<div class="grys_text pointer" @click="kkbrClick">看看别人怎么写的</div>
							</div>
						</div>
						<div v-if="kkbr" class="bianji_kkbr">
							<img class="kkbr_img" src="../../../assets/login/jianli_icon2.png" />
							<div class="kkbr_nr">
								<div class="nr_top">
									<div class="top_left">
										<div class="left_tx"></div>
										<div class="left_name"></div>
									</div>
									<div class="top_right pointer" @click="getAdvantages">换一个</div>
								</div>
								<div class="nr_up">
									{{ contentText }}
								</div>
							</div>
						</div>
						<div class="bianji_fwb">
							<el-input ref="advantage" type="textarea" :rows="7" placeholder="请输入内容"
								v-model="query.advantage" class="bianji_input">
							</el-input>
						</div>
						<div class="bianji_button">
							<div class="button_qx pointer" @click="educationalShow = true">取消</div>
							<div class="button_wc pointer" @click="submitClick">完成</div>
						</div>
					</div>
				</div>
				<!-- 求职意向 -->
				<div class="addition activeTextr">
					<div class="addition_top" v-if="Person.job_intention.length == 0">
						<div class="top_left">求职意向</div>
						<div class="top_right pointer" @click="navTo('/my-resume/objective',1)">
							<div class="">
								<img src="../../../assets/glxt/10.png" alt="" />
							</div>
							<div class="right_tianjia">添加</div>
						</div>
					</div>
					<div class="addition_up" v-if="Person.job_intention.length == 0">
						当前暂时无求职意向，添加后将给您推荐匹配度更高的职位
					</div>
					<div class="addition_top" v-if="Person.job_intention.length> 0">
						<div class="top_left">求职意向</div>
					</div>
					<div class="addition_ups" v-for="item in Person.job_intention" :key="item.id">
						{{ item.one_position }} ｜ {{ item.salary_min }}-{{
              item.salary_max
            }}
						｜ {{ item.position_name }} ｜ {{ item.province }}
					</div>
				</div>
				<!-- 工作经历 -->
				<div class="addition activeTextr">
					<div class="addition_top">
						<div class="top_left">工作经历</div>
						<div class="top_right pointer" @click="navTo('/my-resume/experience',1)"
							v-if="Person.work.length == 0">
							<div class="">
								<img src="../../../assets/glxt/10.png" alt="" />
							</div>
							<div class="right_tianjia">添加</div>
						</div>
					</div>
					<div class="addition_up" v-if="Person.work.length == 0">
						添加您的过往工作经历，展示您的工作能力与成就
					</div>
					<div class="addition_ups-one" v-else>
						<div v-for="item in Person.work" :key="item.id" class="addition_ups-one-item">
							<div class="row-center font-weight-bolder text-main addition_ups-one-heard">
								<div>{{ item.company_name }}</div>
								<div>{{ item.job_times }}</div>
							</div>
							<div class="font-weight-bolder text-main addition_ups-one-title">
								{{ item.work_name }}
							</div>
							<div class="row text-main font-normal">
								<div>内容：</div>
								<div class="workContent">
									<div v-for="(workContent, index) in item.splitAdd" :key="index">
										{{ workContent }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 教育经历 -->
				<div class="addition activeTextr">
					<div class="addition_top">
						<div class="top_left">教育经历</div>
						<div class="top_right pointer" @click="navTo('/my-resume/educational',1)"
							v-if="Person.school.length == 0">
							<div class="">
								<img src="../../../assets/glxt/10.png" alt="" />
							</div>
							<div class="right_tianjia">添加</div>
						</div>
					</div>
					<div class="addition_up" v-if="Person.school.length == 0">
						添加您的教育经历，企业更加了解您
					</div>
					<div class="addition_up-one" v-else>
						<div class="row-center font-normal text-main">
							{{ Person.school[0].school_name }} ｜ {{ Person.school[0].school_times }}
						</div>
						<div class="font-normal text-main addition_up-one-title">
							{{ Person.school[0].major }} ｜ {{ Person.school[0].education }}
						</div>
						<div class="row addition_up-one-bottom font-normal text-main">
							<div>在校经历：</div>
							<div class="workContent-one">
								<div v-for="(workContent, index) in experience_one" :key="index">
									{{ workContent }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 资格证书 -->
				<div class="addition activeTextr">
					<div class="addition_top">
						<div class="top_left">资格证书1</div>
						<div class="top_right pointer" @click="navTo('/my-resume/certificate',1)"
							v-if="!Person.certificate">
							<div class="">
								<img src="../../../assets/glxt/10.png" alt="" />
							</div>
							<div class="right_tianjia">添加</div>
						</div>
					</div>
					<div v-if="Person.certificate">
						<div class="addition_up" v-for="(item, index) in Person.certificate.certificates" :key="index">
							{{ item }}
						</div>
					</div>
					<div class="addition_up" v-if="Person.certificate && Person.certificate.certificates.length==0">暂未添加
					</div>
				</div>
			</div>
		</div>
		<mask-preview ref="maskPreview"></mask-preview>
	</div>
</template>

<script>
	import api from "../../../api/user";
	import maskPreview from "../components/preview.vue"
	export default {
		components: {
			maskPreview
		},

		data() {
			return {
				input: "",
				value1: "",
				kkbr: false,
				educationalShow: true,
				Person: null,
				query: {
					advantage: "",
				},
				experience_one: [],
				contentText: "",
			};
		},

		created() {
			if (this.$route.query.id) {
				this.educationalShow = false
			}
			this.getResume();
		},

		mounted() {},

		methods: {
			previewClick() {
				if (!this.Person.detail || this.Person.detail.completeness < 90) {
					this.$util.msg("你的简历完善度小于90%，请继续完善简历", "warning");
					return;
				}
				this.$refs.maskPreview.show()
			},
			kkbrClick() {
				this.getAdvantages();
				this.kkbr = !this.kkbr;
			},
			// 看看别人怎么写
			getAdvantages() {
				api.getAdvantages().then((res) => {
					if (res.code == 200) {
						this.contentText = res.data.content;
					}
				});
			},
			navTo(path, id) {
				this.$router.push({
					path,
					query: {
						id
					}
				});
			},
			submitClick() {
				if (this.query.advantage.trim() == "") {
					this.$refs['advantage'].focus();
					this.$util.msg("请输入内容", "error");
					return;
				}
				api.setResume(this.query).then((res) => {
					if (res.code == 200) {
						this.$util.msg("编辑成功");
						this.getResume();
						this.educationalShow = true;
					}
				});
			},
			// 获取简历信息
			getResume() {
				const loading = this.$loading({
					lock: true,
					text: '加载中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				api.getResume().then((res) => {
					if (res.code == 200) {
						this.Person = res.data;
						if (res.data.detail) {
							this.query.advantage = res.data.detail.advantage;
							if (res.data.school.length > 0) {
								if (res.data.school[0].experience) {
									this.experience_one = res.data.school[0].experience.split("\n");
								}
							}
							if (res.data.work.length > 0) {
								res.data.work.forEach((item) => {
									if (item.to_hide == 1) {
										item.to_hide = true;
									} else {
										item.to_hide = false;
									}
									if (item.work_content) {
										item.splitAdd = item.work_content.split("\n");
									}
								});
							}
						}
					}
					loading.close()
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 51px;
		background: #ffffff;
		box-shadow: 0px 1px 0px 0px #f3f3f3;
		border-bottom: 2px solid #f3f3f3;

		.title_jl {
			font-size: 14px;
			font-weight: 400;
			color: #414a60;
			margin-left: 30px;
		}

		.title_yl {
			font-size: 14px;
			font-weight: 400;
			color: #126bf9;
			margin-right: 30px;
		}
	}

	.active {
		background: #ffffff;
		overflow: hidden;

		.activeText {
			margin: 17px 0px 30px;

			.activeName {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.nameleft {
					font-size: 24px;
					font-weight: 400;
					color: #24272e;
				}

				.nameimg {
					border-radius: 50%;
					width: 56px;
					height: 56px;
					overflow: hidden;
				}
			}

			.activeExperience {
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.active_xt {
					width: 2px;
					height: 12px;
					background: #e0e0e0;
					margin: 0 14px 0 21px;
				}

				.active_jingyan {
					display: flex;
					align-items: center;

					.experience_icon {
						width: 14px;
						height: 14px;
					}

					.experience {
						margin-left: 10px;
						font-size: 14px;
						font-weight: 400;
						color: #414a60;
					}
				}
			}

			.addition {
				margin: 50px 0 0 0;

				.addition_top {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.top_left {
						font-size: 18px;
						font-weight: 400;
						color: #414a60;
					}

					.top_right {
						display: flex;
						align-items: center;

						.right_blue {
							width: 13px;
							height: 13px;
							background-color: #126bf9;
						}

						.right_tianjia {
							font-size: 13px;
							font-weight: 400;
							color: #126bf9;
							margin-left: 10px;
						}
					}
				}

				.addition_ups {
					margin-top: 1.875rem;
					font-size: 0.875rem;
					font-weight: 400;
					color: #414a60;
				}

				.addition_up {
					margin-top: 30px;
					font-size: 14px;
					font-weight: 400;
					color: #aeb1bb;
				}

				.addition_up-one {
					margin-top: 28px;

					.addition_up-one-title {
						margin: 20px 0;
					}

					.addition_up-one-bottom {
						>div:first-child {
							font-size: 15px;
						}

						.workContent-one {
							line-height: 23px;
							font-size: 14px;
						}
					}
				}
			}
		}
	}

	.activeTextr {
		padding: 0 30px;
	}

	.active_bianji {
		padding: 28px 30px 25px;
	}

	.redact {
		position: relative;
		width: 100%;
		background: #f8f9fb;

		.acvive_grys {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.grys_title {
				font-size: 16px;
				font-weight: 400;
				color: #414a60;
			}

			.grys_flex {
				display: flex;
				align-items: center;

				.grys_img {
					width: 18px;
					height: 12px;
				}

				.grys_text {
					font-size: 13px;
					font-weight: 400;
					color: #126bf9;
					margin-left: 10px;
				}
			}
		}

		.bianji_fwb {
			margin-top: 20px;
		}

		.bianji_button {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: 20px;

			.button_qx {
				width: 90px;
				height: 34px;
				background: #ffffff;
				border: 1px solid #e3e7ed;
				font-size: 14px;
				font-weight: 400;
				color: #414a60;
				text-align: center;
				line-height: 36px;
			}

			.button_wc {
				width: 90px;
				height: 34px;
				background: #126bf9;
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
				text-align: center;
				line-height: 34px;
				margin-left: 20px;
			}
		}
	}

	.bianji_kkbr {
		position: absolute;
		top: 38px;
		right: 14px;
		z-index: 2;
		overflow: hidden;

		.kkbr_img {
			width: 400px;
			height: 234px;
		}

		.kkbr_nr {
			position: absolute;
			top: 38px;
			right: 14px;
			width: 400px;
			height: 234px;

			.nr_top {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.top_left {
					display: flex;
					align-items: center;

					.left_tx {
						width: 34px;
						height: 34px;
						margin-left: 55px;
						border-radius: 50%;
						overflow: hidden;
						// background: red;
					}

					.left_name {
						font-size: 15px;
						font-weight: 400;
						color: #414a60;
						margin-left: 6px;
					}
				}

				.top_right {
					font-size: 14px;
					font-weight: 400;
					color: #126bf9;
					margin-right: 41px;
				}
			}

			.nr_up {
				width: 338px;
				font-size: 13px;
				font-weight: 400;
				color: #61687c;
				margin: 0 12px 0 55px;
				word-wrap: break-word;
			}
		}
	}

	.wfxz {
		z-index: 10;
		user-select: none;
		-webkit-user-seletct: none;
		-moz-user-seletct: none;
		-ms-user-seletct: none;
	}

	.addition_ups-one {
		margin-top: 28px;

		.addition_ups-one-item {
			margin-bottom: 20px;
		}

		.addition_ups-one-item:last-child {
			margin-bottom: 0;
		}

		.addition_ups-one-heard {
			font-size: 15px;

			div:last-child {
				font-weight: 400;
				color: #9fa3b0;
				font-size: 13px;
				margin-left: 20px;
			}
		}

		.addition_ups-one-title {
			font-size: 15px;
			margin: 20px 0px;
		}

		.workContent {
			line-height: 23px;
		}
	}
</style>
